<template>
  <div class="row filter pb-2 pt-2 mb-3">
    <div class="col-lg-1">
      <b class="font-inter">Filtrar por:</b>
    </div>
    <div class="col-md-2">
      <select @change="mtd_filter" id="select-role" class="custom-select custom-select-sm" v-model="equipment_type_id">
        <option disabled :value="null">Seleccionar tipo producto ...</option>
        <option value="all">TODOS</option>
        <option :value="item.id" v-for="(item, index) in equipment_types" :key="index">
          {{ item.name }}
        </option>
      </select>
    </div>
    <div class="col-md-2">
      <input v-model="wordsearch" @keyup="mtd_search" class="form-control form-control-sm" placeholder="Buscar..." />
    </div>
    <div class="col-md-2">
      <a @click="mtd_clear" href="javascript:void(0)">Limpiar filtro</a>
    </div>
  </div>
</template>
<script>
  /** Vuex */
  import { mapActions, mapGetters } from "vuex";
  export default {
    props: ["prop-execute", "page"],
    data() {
      return {
        wordsearch: "",
        equipment_types: [],
        equipment_type_id: null,
      };
    },
    created() {
      this.mtd_getData();
    },
    computed: {
      ...mapGetters(["url_api"]),
    },
    watch: {
      propExecute: function (newValue) {
        if (newValue) this.mtd_executeFilter();
      },
      page: function (newValue) {
        if (newValue) this.mtd_executeFilter();
      },
    },
    methods: {
      ...mapActions(["get", "post", "get_sin_bus", "execute_commit"]),
      mtd_getData: function () {
        this.get({
          url: `${this.url_api}/inventory-filters?f=equipment_types`,
          authorization: true,
          token: localStorage.getItem("token"),
        })
          .then((response) => {
            this.equipment_types = response.equipment_types;
          })
          .catch(() => {
            //
          });
      },
      mtd_filter: function () {
        this.mtd_executeFilter("new");
        this.$emit("emit-init-pagination");
      },
      mtd_executeFilter: function (type = "") {
        let pagination = null;
        if (type == "new") {
          pagination = 1;
        } else {
          pagination = this.page;
        }
        this.get({
          url: `${this.url_api}/products?page=${pagination}&equipment_type=${this.equipment_type_id}&search=${this.wordsearch}`,
          mutation: "INVENTORY__INDEX",
          authorization: true,
          token: localStorage.getItem("token"),
        })
          .then((response) => {
            //
          })
          .catch(() => {
            //
          });
      },
      mtd_search: function () {
        this.get_sin_bus({
          url: `${this.url_api}/products?page=1&equipment_type=${this.equipment_type_id}&search=${this.wordsearch}`,
          mutation: "INVENTORY__INDEX",
          authorization: true,
          token: localStorage.getItem("token"),
        })
          .then((response) => {
            //
          })
          .catch(() => {
            //
          });
      },
      mtd_clear: function () {
        this.execute_commit({
          mutation: "INVENTORY__CLEAR",
        });
        this.wordsearch = "";
        this.equipment_type_id = null;
      },
    },
  };
</script>
