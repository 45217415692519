<template>
  <div class="main-body p-0">
    <div class="inner-wrapper">
      <div class="inner-main">
        <page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel"></page-header>
        <div class="inner-main-body">
          <page-body
            :propHeader="dataTable.header"
            :propBody="dataTable.body"
            :propModel="dataModel"
            @action-next-pagination="mtd_nextPagination"
            @action-sonwindow-close="mtd_sonWindowClose"
            @inventory-details="mtd_productDetails"
            @inventory-asignar="mtd_asignarTrabajador"
          >
            <template slot="content-filter">
              <component
                :is="filter_component"
                @emit-init-pagination="mtd_initPagination"
                :prop-execute="executeFilter"
                :page="dataTable.header.page"
              ></component>
            </template>
            <template slot="content-sonwindow">
              <component
                :is="dataTable.body.sonWindow.component"
                :propRow="dataTable.body.sonWindow.row"
                @action-sonwindow-close="mtd_sonWindowClose"
                @success="mtd_pageSuccess"
              ></component>
            </template>
          </page-body>
        </div>
      </div>
    </div>
    <!--Form: create and update-->
    <modal :id="dataModal1.config.id" :propModel="dataModel" :propConfig="dataModal1.config" @action-close="mtd_closeModal1()">
      <component
        :is="dataModal1.slot.component"
        slot="content-modal"
        :propInfo="dataModal1.slot"
        @onload="mtd_componentOnload"
        @success="mtd_componentSuccess"
        @error="mtd_componentError"
        @close="mtd_closeModal1"
      ></component>
    </modal>
  </div>
</template>
<script>
  /** Vuex */
  import { mapActions, mapGetters } from "vuex";
  /** Components */
  import InventoryForm from "../../components/inventory/Form";
  import InventoryFilterComponent from "../../components/inventory/Filter";
  /** Page */
  import DetailsPage from "./Details";
  import AsignarPage from "./Asignar";
  export default {
    components: {
      InventoryForm,
      InventoryFilterComponent,
      DetailsPage,
      AsignarPage,
    },
    data() {
      return {
        filter_component: "inventory-filter-component",
        executeFilter: 0,
        dataModel: {
          name: "inventory", //AQUI EN INGLES SINGULAR
          alias: "Inventario",
          icon: "fas fa-laptop-code",
        },
        page: {
          header: {
            menu: ["Inventario", "Listar"],
          },
          buttons: {
            create: false,
            import: false,
            export: false,
          },
        },
        dataTable: {
          header: {
            title: "Lista de productos",
            filter: false,
            page: 1,
            wordsearch: "",
          },
          body: {
            cols: [
              {
                name: "equipment_type_name",
                alias: "Tipo equipo",
                width: "",
              },
              {
                name: "brand_name",
                alias: "Marca",
                width: "",
              },
              {
                name: "model",
                alias: "Modelo",
                width: "",
              },
              {
                name: "acquisition_date",
                alias: "Fecha adquisición",
                width: "",
              },
              {
                name: "state",
                alias: "Estado",
                width: "",
                play: {
                  0: "<span class='badge badge-soft-danger'>Inactivo</span>",
                  1: "<span class='badge badge-soft-success'>Activo</span>",
                },
              },
            ],
            buttons: {
              edit: true,
              remove: false,
              create: true,
              width: "",
            },
            myButtons: [
              {
                text: "Detalle",
                tooltip: "Detalle del producto",
                component: "inventory-details",
                can: "change-status",
                thisTitle: "Detalle del producto",
                scroll: true,
              },
              {
                text: "Asignar",
                tooltip: "Asignar producto a trabajador",
                component: "inventory-asignar",
                can: "change-status",
                thisTitle: "Asignar producto a trabajador",
                class: "btn-warning",
                scroll: true,
                play: {
                  type: "state",
                  name: "state_worker",
                  0: true,
                  1: false,
                },
              },
            ],
            sonWindow: {
              component: null,
              row: null,
            },
          },
        },
        dataModal1: {
          slot: {
            component: null,
            data: {
              row: null,
              buttonSubmit: null,
              method: null,
            },
          },
          config: {
            id: "c-modal-1__roles-index",
            idHash: "#c-modal-1__roles-index",
          },
        },
      };
    },
    computed: {
      ...mapGetters(["url_api"]),
    },
    methods: {
      ...mapActions(["get", "post", "destroy", "execute_commit", "update"]),
      ///////////////////// METODO POR DEFECTO /////////////////////
      mtd_componentOnload: function (response) {
        // eslint-disable-next-line no-undef
        $(this.dataModal1.config.idHash).modal("show");
      },

      mtd_componentSuccess: function (response) {
        if (response.component == "access-form") {
          if (response.action == "store") {
            this.executeFilter++;
          }
          if (response.action == "update") {
            this.execute_commit({
              mutation: "INVENTORY__UPDATE",
              payload: {
                data: response.user,
              },
            });
          }
          this.mtd_closeModal1();
        }
      },
      mtd_componentError: function () {
        //
      },
      mtd_closeModal1: function () {
        // eslint-disable-next-line no-undef
        $(this.dataModal1.config.idHash).modal("hide");
        this.dataModal1.slot = {
          component: null,
          data: {
            row: null,
            buttonSubmit: null,
            method: null,
          },
        };
      },
      mtd_sonWindowClose: function () {
        this.dataTable.body.sonWindow = {
          component: null,
          row: null,
        };
      },
      mtd_pageSuccess: function (response) {
        if (response.type == "add_worker_product") {
          this.execute_commit({
            mutation: "INVENTORY__UPDATE",
            payload: {
              data: response.data,
            },
          });
        }
      },
      mtd_nextPagination: function (page) {
        this.dataTable.header.page = page;
      },
      mtd_initPagination: function () {
        this.dataTable.header.page = 0;
      },

      ///////////////////// METODO PROPIOS DEL COMPONENTE /////////////////////
      //CREAR
      mtd_create: function () {
        this.dataModal1.slot.component = "access-form";
        this.dataModal1.slot.data = {
          row: null,
          buttonSubmit: "Registrar usuario",
          method: "create",
        };
        this.dataModal1.config.scroll = true;
        this.dataModal1.config.size = "modal-md";
        this.dataModal1.config.title = "<span class = 'fa fa-plus'></span> Nuevo usuario";
      },
      //EDITAR
      mtd_edit: function (role) {
        this.dataModal1.slot.component = "access-form";
        this.dataModal1.slot.data = {
          row: role,
          buttonSubmit: "Actualizar usuario",
          method: "edit",
        };
        this.dataModal1.config.scroll = true;
        this.dataModal1.config.size = "modal-md";
        this.dataModal1.config.title = "<i class='far fa-edit'></i> Editando usuario";
      },
      //ELIMINAR
      mtd_remove: function (role) {
        this.destroy({
          url: `${this.url_api}/roles/${role.id}`,
          authorization: true,
          token: localStorage.getItem("token"),
          mutation: "INVENTORY__REMOVE",
        })
          .then(() => {
            window.message("success", "Eliminación exitosa.", "Accesos");
          })
          .catch((error) => {
            //
          });
      },
      //PRODUCT DETAILS
      mtd_productDetails: function (product) {
        this.dataTable.body.sonWindow = {
          component: "details-page",
          row: product,
        };
      },
      //ASIGNAR TRABAJADOR
      mtd_asignarTrabajador: function (product) {
        this.dataTable.body.sonWindow = {
          component: "asignar-page",
          row: product,
        };
      },
    },
    beforeDestroy() {
      this.execute_commit({
        mutation: "INVENTORY__CLEAR",
      });
    },
  };
</script>
