t
<template>
  <div class="form form-horizontal form-scrollable">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="d-block">Trabajador</label>
            <select2-zh
              @select="selectWorker($event)"
              :placeholder="'Buscar trabajador'"
              :url="url_api + '/workers/search'"
              name="category_id"
              :selecteditems="[]"
              identifier="product"
            >
              >
            </select2-zh>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer border-top">
      <button @click="mtd_save" :disabled="cp_save" class="btn btn-sm btn-primary">Guardar</button>
      <button type="button" class="btn btn-sm btn-secondary" @click="mtd_close">Cancelar</button>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    props: ["propRow"],
    data() {
      return {
        workers: [],
        state: false,
        store: {
          product_id: this.propRow.id,
          worker_id: null,
        },
      };
    },
    created() {
      this.mtd_getData();
    },
    computed: {
      ...mapGetters(["url_api"]),
      cp_save: function () {
        let state = true;
        if (this.store.worker_id != null) {
          state = false;
        }
        return state;
      },
    },
    methods: {
      ...mapActions(["post"]),
      mtd_close: function () {
        $("#sonWindow").modal("hide");
        this.$emit("action-sonwindow-close");
      },
      mtd_getData() {
        this.state = true;
        $("#sonWindow").modal("show");
      },
      selectWorker: function ({ id, text }) {
        this.store.worker_id = id;
      },
      mtd_save: function () {
        this.post({
          url: `${this.url_api}/workers/add_product`,
          authorization: true,
          token: localStorage.getItem("token"),
          params: this.store,
        }).then((response) => {
          this.$alert("Producto asignado correctamente.", "Mensaje", "success").then({});
          $("#sonWindow").modal("hide");
          this.$emit("action-sonwindow-close");
          this.$emit("success", {
            data: response.data,
            type: "add_worker_product",
          });
        });
      },
    },
  };
</script>
