<template>
  <div class="form form-horizontal form-scrollable">
    <div class="modal-body">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Tipo</th>
              <th scope="col">Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in details" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    props: ["propRow"],
    data() {
      return {
        details: [],
      };
    },
    created() {
      this.mtd_getDetails();
    },
    computed: {
      ...mapGetters(["url_api"]),
    },
    methods: {
      ...mapActions(["get"]),

      mtd_getDetails() {
        this.get({
          url: `${this.url_api}/products/${this.propRow.id}/details`,
          authorization: true,
          token: localStorage.getItem("token"),
        }).then((response) => {
          this.details = response.data;
          $("#sonWindow").modal("show");
        });
      },
    },
  };
</script>
